<template>
  <b-modal
    id="import-ratings-modal"
    ref="import-ratings-modal"
    :title="$t('table.importPlanRating')"
    :ok-disabled="$v.$invalid || processing"
    :ok-title="processing ? $t('table.importing') + '...' : $t('table.import')"
    :cancel-title="$t('table.cancel')"
    @ok.prevent="importRatings"
    @hidden="clearFields"
  >
    <form @submit.stop.prevent="checkIfValidThenEnter">
      <input type="submit" value="Submit" class="hidden-submit" />
      <b-form-group :label="$t('table.selectFile')">
        <b-form-file
          v-model="file"
          :placeholder="$t('table.selectFileOrDrop')"
          :drop-placeholder="$t('table.dropPlaceholder')"
          :browse-text="$t('table.browse')"
          accept=".xlsx, .xls"
          required
        ></b-form-file>
      </b-form-group>

      <b-form-group :label="$t('table.date')" label-for="datepicker-buttons-rating">
        <datepicker-wrapper
          id="datepicker-buttons-rating"
          v-model="day"
          required
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import errorsHandler from '@/utils/errorsHandler';
import { BFormFile } from 'bootstrap-vue';
import DatepickerWrapper from '@/components/DatepickerWrapper.vue';

export default {
  name: 'ModalImportPlanRatings',
  components: { BFormFile, DatepickerWrapper },
  props: {
    channelId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      day: '',
      file: null,
      processing: false,
    };
  },
  validations: {
    day: { required },
    file: { required },
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
    }),
  },
  watch: {},
  methods: {
    clearFields() {
      this.day = '';
      this.file = null;
    },

    hideModalImportRatings() {
      this.$bvModal.hide('import-ratings-modal');
    },

    async importRatings() {
      this.processing = true;
      let formData = new FormData();
      formData.append('channel_id', this.channelId);
      formData.append('day', this.day);
      formData.append('file', this.file);
      await this.$store.dispatch('POST_GRP_UPLOAD', {
        formData,
        handler: (res) => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.ratingsImported'),
          });
          this.processing = false;
          this.hideModalImportRatings();
          this.$emit('updateData');
        },
        handlerError: (errors) => {
          this.processing = false;
          errorsHandler(errors, this.$notify);
        },
      });
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid) this.importRatings();
    },
  },
};
</script>

<style lang="sass" scoped></style>
